import $i18n from '@alife/panda-i18n';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { tap, retryWhen, delay } from 'rxjs/operators';
import WetSocket from './socket';
const STATUS_NOT_CONNECTED = 0;
const STATUS_CONNECTED = 1;
const STATUS_RECONNECT = 2;
const STATUS_ERROR = -1;
/**
 * @description 基础类
 * @author 武曲(xiachen.gxc@cainiao.com)
 * @date 2020-03-02
 * @class Wet
 */
class WebSocketClient {
    /**
     * @description 重连最大次数
     * @static
     * @memberof WebSocketClient
     */
    WET_MAX_ATTEMPTS = 10;
    /**
     * @description 状态
     * @memberof WebSocketClient
     */
    status = new BehaviorSubject(STATUS_NOT_CONNECTED);
    /**
     * @description 当前重试次数
     * @memberof WebSocketClient
     */
    attemptTimes = new BehaviorSubject(0);
    /**
     * @description 模块名称，用于日志
     * @memberof WebSocketClient
     */
    name;
    /**
     * @description 接口地址
     * @memberof WebSocketClient
     */
    url;
    /**
     * @description 给wet发送请求的对象
     * @memberof WebSocketClient
     */
    input;
    /**
     * @description wet当前版本
     * @memberof WebSocketClient
     */
    version = '0.0.0';
    /**
     * @description wet当前umid
     * @memberof WebSocketClient
     */
    umid = '';
    connectionStatus;
    messages;
    wetAttemptTimes = new BehaviorSubject(0);
    /**
     *Creates an instance of Wet.
     * @author 武曲(xiachen.gxc@cainiao.com)
     * @date 2020-03-02
     * @param {*} [opt={}]
     * @memberof WebSocketClient
     */
    constructor(opt = { url: '', name: '' }) {
        const { url = 'wss://localhost:13528', name = 'unknow websocket client' } = opt;
        this.name = name;
        this.url = url;
        this._init();
    }
    /**
     * @description 初始化函数
     * @author 武曲(xiachen.gxc@cainiao.com)
     * @date 2020-03-02
     * @memberof WebSocketClient
     * @private
     */
    _init() {
        this.input = new Subject();
        // this.input = new Subject(0); TS 报错构造函数没有入参，暂时去除
        const { messages, connectionStatus } = WetSocket({
            url: this.url,
            input: this.input,
        });
        this.connectionStatus = connectionStatus;
        this.messages = messages
            .pipe(retryWhen((err) => err.pipe(tap(() => {
            this.status.next(STATUS_RECONNECT);
            if (this.wetAttemptTimes?.getValue?.() >= this.WET_MAX_ATTEMPTS) {
                this.wetAttemptTimes?.next?.(STATUS_ERROR);
                this.logger($i18n.get({
                    id: 'FailedToConnectToWebsocketService_1148050991',
                    dm: '连接websocket服务失败',
                    ns: 'CnPrint',
                }));
                throw new Error($i18n.get({
                    id: 'FailedToConnectToWebsocketService_1148050991',
                    dm: '连接websocket服务失败',
                    ns: 'CnPrint',
                }));
            }
            else {
                this.wetAttemptTimes?.next?.(this.wetAttemptTimes.getValue() + 1);
                const newTime = this.wetAttemptTimes?.getValue?.() || 0;
                this.logger($i18n.get({
                    id: 'AWebsocketConnectionErrorOccurredThe_119975534',
                    dm: 'websocket连接发生错误，尝试重新连接中, 当前第 {newTime} 次尝试',
                    ns: 'CnPrint',
                }, { newTime }));
            }
        }), delay(5000))))
            .pipe(tap(() => {
            this.status.next(STATUS_CONNECTED);
        }));
    }
    /**
     * @description 日志
     * @author 武曲(xiachen.gxc@cainiao.com)
     * @date 2020-03-02
     * @param {*} message
     * @param {string} [type='normal']
     * @memberof WebSocketClient
     */
    logger(message, type = 'normal') {
        const messageWithName = `[${this.name}]: ${message}`;
        switch (type) {
            case 'normal': {
                console.log(messageWithName); // eslint-disable-line
                break;
            }
            case 'warn': {
                console.warn(messageWithName); // eslint-disable-line
                break;
            }
            case 'error': {
                console.error(messageWithName); // eslint-disable-line
                break;
            }
            case 'debug': {
                console.debug(messageWithName); // eslint-disable-line
                break;
            }
            default: {
                console.log(messageWithName); // eslint-disable-line
            }
        }
    }
    stringify() {
        return (source) => new Observable((observer) => source.subscribe({
            next(x) {
                observer.next(JSON.stringify(x));
            },
            error(err) {
                observer.error(err);
            },
            complete() {
                observer.complete();
            },
        }));
    }
    isWetReady() {
        return this.status.getValue() === STATUS_CONNECTED;
    }
}
/** 模块导出 */
export default WebSocketClient;
