import { useEffect, useState } from 'react';
import { CnPrint, doLog } from './core';
export { CnPrint, doLog };
export function useCnPrintInstance(props = { isMixPrint: false }) {
    const { isMixPrint } = props;
    const [printInstance, setPrintInstance] = useState(null);
    useEffect(() => {
        if (isMixPrint) {
            setPrintInstance(new CnPrint({
                isNeedWetPrint: true,
                isNeedCloudPrint: true,
                isNeedPddPrint: true,
                isNeedJdPrint: true,
                isNeedBytePrint: true,
                isNeedKuaiShouPrint: true,
                isNeedVipPrint: true,
                isNeedXhsPrint: true,
                isNeedWxsphPrint: true,
                isNeedDwPrint: true,
                isNeedAkcPrint: true, // 是否开启爱库存打印
            }));
        }
        else {
            setPrintInstance(new CnPrint());
        }
    }, []);
    return printInstance;
}
window.CnPrint = CnPrint;
window.useCnPrintInstance = useCnPrintInstance;
