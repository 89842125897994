import { Observable, BehaviorSubject } from 'rxjs';
import { MessageCenter, doLog } from '../event';
import * as socketStatus from '../const';
import { CAINIAO } from '../const';
import MapSource from './map-source';
function SocketteWebsocket(opt = {
    url: '',
    input: null,
}) {
    const { url, input } = opt;
    const connectionStatus = new BehaviorSubject(socketStatus.WEBSOCKET_INIT);
    const messages = new Observable((observer) => {
        let inputSubscription;
        let open = false;
        let forcedClose = false;
        const closed = () => {
            if (!open) {
                return;
            }
            connectionStatus.next(socketStatus.WEBSOCKET_CLOSED);
            open = false;
        };
        connectionStatus.next(socketStatus.WEBSOCKET_OPENING);
        const ws = new WebSocket(url);
        let mapSourceInstance = null;
        ws.onopen = () => {
            open = true;
            connectionStatus.next(socketStatus.WEBSOCKET_OPENED);
            mapSourceInstance = new MapSource('SENDTWETMAP', 
            // TODO 这边按类型看是错误的
            input, doLog, MessageCenter);
            inputSubscription = input?.subscribe((data) => {
                ws.send(data);
                try {
                    mapSourceInstance?.addItem(data);
                }
                catch (e) {
                    console.error(e);
                }
            });
        };
        ws.onmessage = (message) => {
            // add by tdd
            try {
                const res = JSON.parse(message.data);
                const data = (res.data || {});
                const { cmd, taskID = '', status, errorCode, msg } = data;
                const listenData = {
                    ...res,
                    dataSource: CAINIAO,
                    taskID,
                };
                if (cmd === 'print') {
                    if (status === 'success') {
                        listenData.success = true;
                        listenData.cmd = 'notifyPrintResult';
                    }
                    else {
                        listenData.success = false;
                        listenData.cmd = 'notifyPrintError';
                        listenData.error = {
                            code: errorCode,
                            message: msg,
                        };
                    }
                }
                MessageCenter.listen(listenData);
                if (cmd === 'print') {
                    const traceId = MessageCenter.getTaskById && MessageCenter.getTaskById(taskID)
                        ? MessageCenter.getTaskById(taskID)
                        : taskID;
                    doLog({
                        traceId,
                        taskID,
                        dataSource: CAINIAO,
                        process: 'FRONT_END',
                        action: 'notifyPrintResultResponse',
                        accountNo: '',
                        accountName: '',
                        bizCode: '',
                        docType: 'WAY_BILL',
                        printNode: 'NORMAL',
                        status: listenData.success ? 'success' : 'failed',
                        response: res,
                        errorCode: '20001',
                        errorMessage: '',
                    });
                }
                data && data.taskID && mapSourceInstance?.removeItem(data.taskID);
            }
            catch (e) {
                console.error(e);
            }
            observer.next(message.data);
        };
        ws.onclose = (e) => {
            // prevent observer.complete() being called after observer.error(...)
            if (!open) {
                return;
            }
            closed();
            if (forcedClose) {
                connectionStatus.next(socketStatus.WEBSOCKET_COMPLETE);
                observer.complete();
            }
            else {
                connectionStatus.next(socketStatus.WEBSOCKET_ERROR);
                observer.error(new Error(e.reason));
            }
        };
        ws.onerror = (err) => {
            closed();
            connectionStatus.next(socketStatus.WEBSOCKET_ERROR);
            observer.error(err);
        };
        return () => {
            forcedClose = true;
            if (inputSubscription) {
                inputSubscription.unsubscribe();
            }
            if (open) {
                closed();
                ws.close();
            }
        };
    });
    return { messages, connectionStatus };
}
export default SocketteWebsocket;
