import $i18n from '@alife/panda-i18n';
import { Subject, from } from 'rxjs';
import { mergeAll, map, share } from 'rxjs/operators';
import { getUUID } from 'src/utils';
import cloudPrint, { getDocs as getCloudPrintDocs } from './cloud-print';
import Wet from '../wet';
import localPrint from './local-print';
import { noop } from '../const';
const MESSAGE_TYPE_INIT = 1;
class Print extends Wet {
    static bussinessType = 1;
    messages$;
    input$;
    isReady = false;
    constructor(opt = {}) {
        const { url = 'wss://localhost:8080', wetConnectRetry = 0 } = opt;
        super({
            url,
            bussinessType: Print.bussinessType,
            initMessageType: MESSAGE_TYPE_INIT,
            name: $i18n.get({ id: '31255238681956352.CNTM', dm: '打印', ns: 'CnPrint' }),
        });
        this.WET_MAX_ATTEMPTS = wetConnectRetry;
        this.messages$ = this.messages?.pipe?.(share());
        this.input$ = this.createPrint(this.input);
    }
    createPrint(input) {
        const subject = new Subject();
        subject
            .pipe(map((printDatas) => (Array.isArray(printDatas) ? from(printDatas) : printDatas)), mergeAll())
            .pipe(map((printData) => this.printByType(printData)), mergeAll(), map((printData) => ({
            ...printData,
            bussinessType: Print.bussinessType,
        })))
            .pipe(this.stringify())
            .subscribe((data) => input?.next(data));
        return subject;
    }
    printData(result) {
        const { printConfig = {}, data = [] } = result;
        const printData = {
            businessType: 1,
            messageType: 3,
            configName: printConfig.configName,
            data: {
                requestID: getUUID(),
                version: '1.0',
                cmd: 'print',
                task: {
                    taskID: new Date().getTime() + getUUID(),
                    documents: data,
                },
                ...printConfig,
            },
        };
        this.input?.next(this.wetStringify(printData));
    }
    doPrint(data) {
        this.input$?.next(data);
    }
    printByType(printData) {
        if (printData?.printConfig?.printType === 'cloud') {
            return cloudPrint(printData);
        }
        return localPrint(printData);
    }
    isValidePrintData(printData) {
        if (!printData.printConfig) {
            this.logger($i18n.get({
                id: 'PrintDataErrorPrintConfigurationDoes_1982281991',
                dm: '打印数据错误，打印配置不存在',
                ns: 'CnPrint',
            }));
            return false;
        }
        if (!printData.data || printData.data.length < 0) {
            this.logger($i18n.get({
                id: 'PrintDataErrorPrintDataDoes_718870573',
                dm: '打印数据错误，打印数据不存在',
                ns: 'CnPrint',
            }));
            return false;
        }
    }
    filterClourPrintDoc(printDatas = []) {
        try {
            return printDatas.map((printData) => getCloudPrintDocs(printData));
        }
        catch (err) {
            console.error(err);
            return [];
        }
    }
    subscribe(callback = noop) {
        const subscription = this.messages$?.subscribe({
            next: (data) => callback({
                success: true,
                data,
            }),
            error: (error) => callback({
                success: false,
                data: {},
                error: {
                    message: error.message,
                },
            }),
            complete: () => callback({
                success: true,
                data: {},
                complete: true,
            }),
        });
        return () => {
            subscription?.unsubscribe();
        };
    }
}
export default Print;
