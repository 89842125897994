import $i18n from '@alife/panda-i18n';
class MapSource {
    storeKey;
    storeMap = new Map();
    callBack = null;
    storageEvent = new Event('storageChange');
    doLog = null;
    timer;
    MessageCenter = null;
    constructor(storeKey, callBack, doLog, MessageCenter) {
        const oldData = localStorage.getItem(storeKey);
        this.storeKey = storeKey;
        this.callBack = callBack;
        this.doLog = doLog;
        this.MessageCenter = MessageCenter;
        let oldMap = {};
        try {
            oldMap = (oldData ? JSON.parse(oldData) : {});
        }
        catch (e) {
            // do nothing
        }
        Object.keys(oldMap).forEach((key) => {
            if (oldMap[key]) {
                this.storeMap.set(key, oldMap[key]);
            }
        });
        this.startTimer();
        window.addEventListener('storageChange', () => {
            this.startTimer();
        });
    }
    addItem(content) {
        const originData = this.parseWet(content) || {};
        const { tryTimes = 0, data } = originData;
        const { task } = data || {};
        const { taskID = '' } = task || {};
        if (tryTimes || !taskID) {
            return;
        }
        const key = taskID;
        const value = originData;
        this.storageEvent.newValue = value;
        value.timeStamp = Date.now();
        this.storeMap.set(key, value);
        const store = this.mapToJson(this.storeMap);
        this.storageEvent.store = store;
        localStorage.setItem(this.storeKey, JSON.stringify(store));
        window.dispatchEvent(this.storageEvent);
    }
    removeItem(key) {
        const newValue = this.storeMap.get(key);
        if (!newValue) {
            return;
        }
        newValue && (this.storageEvent.newValue = newValue);
        newValue && this.storeMap.delete(key);
        if (newValue && newValue.tryTimes) {
            try {
                const { data } = newValue;
                const { task, traceId, bizCode } = data || {};
                const { taskID = '', printer = '' } = task || {};
                this?.doLog?.({
                    process: 'FRONT_END',
                    action: 'retrySendSuccess',
                    traceId: traceId,
                    bizCode: bizCode,
                    printer,
                    taskID,
                });
            }
            catch (e) {
                // do nothing
            }
        }
        const store = this.mapToJson(this.storeMap);
        this.storageEvent.store = store;
        localStorage.setItem(this.storeKey, JSON.stringify(store));
        window.dispatchEvent(this.storageEvent);
    }
    startTimer() {
        let obj = this.mapToJson(this.storeMap);
        const isEmpty = Object.keys(obj);
        if (isEmpty.length) {
            this.timer = setInterval(() => {
                obj = this.mapToJson(this.storeMap);
                Object.keys(obj).forEach((key) => {
                    const item = obj[key];
                    const { data } = item;
                    const { task, traceId = '', bizCode } = data || {};
                    const { taskID = '', printer = '' } = task || {};
                    if ((item?.tryTimes || 0) >= 2 || Date.now() - (item?.timeStamp || 0) > 3600000) {
                        this.removeItem(taskID);
                        try {
                            this?.doLog?.({
                                process: 'FRONT_END',
                                action: 'retrySendFailed',
                                request: item,
                                traceId,
                            });
                            const res = {
                                dataSource: 'CAINIAO',
                                data: {
                                    cmd: 'notifyPrintError',
                                    errorMessage: $i18n.get({
                                        id: 'ThePrintingTaskIsAbnormalPlease_1385339721',
                                        dm: '打印任务异常，请检查是否有漏打情况',
                                        ns: 'CnPrint',
                                    }),
                                    ...item,
                                    traceId,
                                    taskID,
                                    printer,
                                    bizCode,
                                },
                            };
                            this.MessageCenter?.listen?.(res);
                        }
                        catch (e) {
                            // do nothing
                        }
                    }
                    if (Date.now() - (item?.timeStamp || 0) >= 60000 &&
                        (!item.tryTimes || item.tryTimes < 2)) {
                        item.tryTimes = item.tryTimes ? item.tryTimes + 1 : 1;
                        item.timeStamp = Date.now();
                        this.callBack && this.callBack?.next?.(this.stringifyWet(item));
                    }
                });
            }, 30000);
        }
        else {
            clearInterval(this.timer);
        }
    }
    clearItem() {
        this.storeMap.clear();
        clearInterval(this.timer);
    }
    parseWet(data) {
        let result = '';
        let output;
        if (typeof data !== 'string') {
            return {};
        }
        result = data.replace(String.fromCharCode(2), '').replace(String.fromCharCode(3), '');
        try {
            output = JSON.parse(result);
        }
        catch (e) {
            // do nothing
        }
        return output;
    }
    stringifyWet(data) {
        return String.fromCharCode(2) + JSON.stringify(data) + String.fromCharCode(3);
    }
    mapToJson(tmpMap) {
        const jsonObj = {};
        for (const [key, value] of tmpMap) {
            jsonObj[key] = value;
        }
        return jsonObj;
    }
    jsonToMap(origin) {
        const newMap = new Map();
        Object.keys(origin).forEach((key) => {
            newMap.set(key, origin[key]);
        });
        return newMap;
    }
}
export default MapSource;
