class EventManager {
    static listener = {};
    supposeCloud;
    taskCache = new Map();
    static get Instance() {
        if (window.PRINT_EVENT_INSTANCE) {
            return window.PRINT_EVENT_INSTANCE;
        }
        window.PRINT_EVENT_INSTANCE = new EventManager();
        return window.PRINT_EVENT_INSTANCE;
    }
    register(name, handler) {
        if (!EventManager.listener[name]) {
            EventManager.listener[name] = [];
        }
        EventManager.listener[name].push(handler);
    }
    unregister(name, handler) {
        if (handler) {
            const listenerList = EventManager.listener[name];
            if (listenerList) {
                EventManager.listener[name] = listenerList.filter((item) => item !== handler);
            }
        }
        else {
            EventManager.listener[name] = [];
        }
    }
    broadcast(name, data) {
        const listeners = EventManager.listener[name];
        if (listeners) {
            for (const handler of listeners) {
                handler(data);
            }
        }
    }
    addTaskCache(taskID, content) {
        this.taskCache.set(taskID, content || true);
    }
    removeTaskCache(taskID) {
        this.taskCache.delete(taskID || '');
    }
    clearTaskCache() {
        this.taskCache.clear();
    }
    getTaskCacheSize() {
        return this.taskCache.size;
    }
    getTaskById(taskID) {
        return this.taskCache.get(taskID || '');
    }
}
export default EventManager;
