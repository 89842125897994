import $i18n from '@alife/panda-i18n';
import { CnDialog, CnMessage } from '@alife/cn-ui';
export function getUUID() {
    const len = 8;
    let radix = typeof window.radix === 'undefined' ? 16 : window.radix;
    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    const uuid = [];
    let i;
    radix = radix || chars.length;
    if (len) {
        for (i = 0; i < len; i++)
            uuid[i] = chars[0 | (Math.random() * radix)];
    }
    else {
        let r;
        uuid[8] = '-';
        uuid[13] = '-';
        uuid[18] = '-';
        uuid[23] = '-';
        uuid[14] = '4';
        for (i = 0; i < 36; i++) {
            if (!uuid[i]) {
                r = 0 | (Math.random() * 16);
                uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
            }
        }
    }
    return uuid.join('');
}
export function getCurrentTime() {
    const time = new Date();
    return `[${time.getFullYear()}/${time.getMonth() + 1}/${time.getDate()} ${time.getHours()}:${time.getMinutes()}:${time.getSeconds()}:${time.getMilliseconds()}]`;
}
export function getUserInfo() {
    const userInfo = (window.headerGlobalData?.getData?.('userInfo') || {});
    return {
        accountNo: userInfo?.accountName || '',
        accountName: userInfo?.userName || '',
        resCode: window.headerGlobalData?.getData?.('warehouseId') || '',
        resName: window.headerGlobalData?.getData?.('warehouseName') || '',
    };
}
export function formatData(data) {
    const { documents = [] } = data;
    const arr = [];
    const configs = {};
    let index = 1;
    let taskID = (data.taksID || data.taskId);
    if (!taskID) {
        taskID = `${new Date().getTime()}${getUUID()}`;
    }
    documents.forEach((item) => {
        const { configName } = item;
        if (!configs[configName]) {
            configs[configName] = index;
            index++;
            arr[index - 1] = {
                configName: item.configName,
                documents: [],
                taskID: `${taskID}${index}`,
                pdfPrint: item.pdfPrint,
            };
        }
        arr[index - 1]?.documents?.push?.(item);
    });
    return arr;
}
export function deliveryTasksCallback(res, config) {
    const { success } = res;
    const { successToast = $i18n.get({
        id: 'ThePrintTaskWasSuccessfullyDistributed_263889204',
        dm: '下发打印任务成功',
    }), errorToast = $i18n.get({
        id: 'IfThePrintingTaskFailsPlease_579791243',
        dm: '下发打印任务失败, 请到单据补打页面进行补打',
    }), } = config;
    if (success) {
        CnMessage.success(successToast);
    }
    else {
        CnMessage.error(errorToast);
    }
}
export function assembleRequestParams(taskId, warehouseId, selectIds, config) {
    const { billType, printType, extendParams = {}, paramKey = 'select', printerList = [] } = config;
    return {
        taskId,
        billType,
        printType,
        warehouseId,
        [paramKey]: selectIds,
        personalParams: extendParams,
        configInfos: printerList,
    };
}
export function transformBatchTaskInfo(selectedRecords = [], selectedRowKeys = [], warehouseId = '', userId = '', config = {}) {
    const { quantity = '', paramFormat } = config;
    const taskId = `${new Date().getTime()}${Math.floor(Math.random() * 10000000000)}`;
    let selectIds = selectedRowKeys;
    if (paramFormat?.toLowerCase?.() === 'string') {
        selectIds = selectedRowKeys.join(',');
    }
    const requestParams = assembleRequestParams(taskId, warehouseId, selectIds, config);
    let quantityTotal = 0;
    // 转化任务备注信息 从所有勾选的的要打印数据中数据
    const taskRemark = selectedRecords.map((item) => {
        // 计算数量
        const num = (item[quantity] ? item[quantity] : 1);
        quantityTotal += num;
        return transformRemark(item, config);
    });
    return assembleTaskInfo({
        taskId,
        userId,
        taskRemark,
        warehouseId,
        quantityTotal,
        requestParams,
    }, config);
}
export function handleSelectedKeys(selectedRowKeys, config) {
    const { billType = '', printType = '' } = config;
    const selectedRowKeysTemp = [];
    const strKeys = window.localStorage.getItem('selectedRowKeysStorage') || JSON.stringify({ nSize: 0 });
    let objKeys = JSON.parse(strKeys);
    selectedRowKeys.forEach((id) => {
        const key = `${id}_${billType}_${printType}`;
        if (!objKeys[key]) {
            objKeys[key] = true;
            selectedRowKeysTemp.push(id);
        }
    });
    if ((objKeys?.nSize || 0) >= 1000) {
        // 暂定1000条数据清空一下缓存（界面最多一次操作100条，所以保留10次操作的id）
        objKeys = { nSize: selectedRowKeys.length };
        // 如果大于1000 将原来的所有前置打印都放到缓存中
        selectedRowKeys.forEach((id) => {
            const key = `${id}_${billType}_${printType}`;
            objKeys[key] = true;
        });
    }
    else {
        objKeys.nSize += selectedRowKeysTemp.length;
    }
    window.localStorage.setItem('selectedRowKeysStorage', JSON.stringify(objKeys));
    return selectedRowKeysTemp;
}
export function getSelectedRecords(selectedRecords = [], selectedRowKeys = [], config = {}) {
    const { selectedRowKey = 'id' } = config;
    const objSelectedRecords = {};
    const resSelectedRecords = [];
    selectedRecords.forEach((item) => {
        objSelectedRecords[item[selectedRowKey]] = item;
    });
    selectedRowKeys.forEach((key) => {
        if (objSelectedRecords[key]) {
            resSelectedRecords.push(objSelectedRecords[key]);
        }
    });
    return resSelectedRecords;
}
export function transformTaskInfo(selectedRecord = {}, warehouseId = '', userId = '', config = {}) {
    const { quantity = '', paramFormat = 'array', selectedRowKey = 'id' } = config;
    const taskId = `${new Date().getTime()}${Math.floor(Math.random() * 10000000000)}`;
    const idTemp = selectedRecord[selectedRowKey];
    let selectIds = [idTemp];
    if (paramFormat.toLowerCase() === 'string') {
        selectIds = idTemp;
    }
    const requestParams = assembleRequestParams(taskId, warehouseId, selectIds, config);
    const quantityTotal = selectedRecord[quantity];
    const taskRemark = transformRemark(selectedRecord, config);
    return assembleTaskInfo({
        taskId,
        userId,
        taskRemark,
        warehouseId,
        quantityTotal,
        requestParams,
    }, 
    // eslint-disable-next-line
    config);
}
function assembleTaskInfo(data, config) {
    const { url = '', billType = '', printType = '', bizCode = [], customData = [], extendDocRemark = [], printer = {}, } = config;
    const { taskId = '', userId = '', taskRemark = [], warehouseId = '', quantityTotal = '---', requestParams = {}, } = data;
    return {
        url: url.indexOf('http:') > -1 || url.indexOf('https:') > -1
            ? url
            : `${window.location.origin}${url}`,
        userId,
        taskId,
        billType,
        printType,
        warehouseId,
        quantity: `${quantityTotal}`,
        bizCode: JSON.stringify(bizCode),
        params: JSON.stringify(requestParams),
        customData: JSON.stringify(customData),
        extendTaskRemark: JSON.stringify(taskRemark),
        extendDocRemark: JSON.stringify(extendDocRemark),
        printer,
    };
}
function transformRemark(data, config) {
    const { extendTaskRemark = [] } = config;
    return extendTaskRemark.map((item) => {
        return {
            ...item,
            value: data[item.value] || '',
        };
    });
}
export function getProdHostName() {
    if (location.hostname.indexOf('pre-') === 0 || location.hostname.indexOf('-stg') > 0) {
        return 'https://pre-prod.wms.cainiao.com';
    }
    if (location.hostname.indexOf('cainiao.test') > 0 ||
        location.hostname.indexOf('taobao.net') > 0) {
        return 'https://wmsprod.daily.taobao.net';
    }
    return 'https://prod.wms.cainiao.com';
}
export const isAndroid = /android/i.test(window.navigator.userAgent);
let isApiReady = isAndroid && !!window.WindVane;
if (isAndroid && !isApiReady) {
    const scriptNode = document.createElement('script');
    scriptNode.src = 'https://g.alicdn.com/mtb/lib-windvane/2.1.8/windvane.js';
    scriptNode.onload = () => {
        isApiReady = true;
    };
    document.body.appendChild(scriptNode);
}
export function callWindVane(className, methodName, params, success = () => { }, error = () => { }) {
    if (isApiReady) {
        window.WindVane.call(className, methodName, params, success, error);
    }
    else {
        CnDialog.alert({
            title: $i18n.get({
                id: 'ThePrintControlIsAbnormalPlease_1471847901',
                dm: '打印控件异常，请确认后刷新页面重试！',
            }),
            size: 'small',
        });
    }
}
