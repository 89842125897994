import $i18n from '@alife/panda-i18n';
import { Observable } from 'rxjs';
import 'src/types/cn-print';
const LOCAL_PRINT_MESSAGE_TYPE = 1;
function localPrint(printData) {
    const { printConfig = {} } = printData;
    return new Observable((observer) => {
        printData?.data?.forEach((data) => {
            const bus_id = (printData.printConfig && printData.printConfig.bus_id) || -1;
            const warehouseId = (printData.printConfig && printData.printConfig.warehouseId) || -1;
            if (printConfig?.templateCode) {
                return observer.next({
                    bus_id,
                    warehouseId,
                    messageType: LOCAL_PRINT_MESSAGE_TYPE,
                    ...printConfig,
                    data: printTemplateTransform(data, printConfig.templateCode),
                });
            }
            observer.next({
                bus_id,
                warehouseId,
                messageType: LOCAL_PRINT_MESSAGE_TYPE,
                ...printConfig,
                data,
            });
        });
    });
}
function printTemplateTransform(input, templateCode) {
    switch (templateCode) {
        case 'pickList': {
            return transformPickList(input);
        }
        case 'deliveryOrder/tmalldeliveryOrder':
            return transformTmalldeliveryOrder(input);
        case 'deliveryOrderPrintTemplateForYD':
            return transformYD(input);
        case 'deliveryOrder/dd':
            return transfromDangDang(input);
        case 'deliveryOrderPrintTemplateForLRLZ':
            return transformLRLZ(input);
        case 'SFPaperPrintTemplate':
            return transfromSF(input);
        case 'complexSortFormPrintTemplate':
            return transformComplexSort(input);
        default:
            return input;
    }
}
function transformPickList(input) {
    let itemBarCodeLength = 0;
    let pickPositionCode = '';
    let pickPoint = [];
    let htmlStr = '';
    const vals = input;
    vals.recordCount = vals.pickBillDetail.length;
    vals.pickBillCodeShort = ''; // 拣选单5位
    vals.pickBillCodeShort = vals.pickBillCode.substr(vals.pickBillCode.length - 5, 5);
    vals.pickBillDetail.forEach((item, key) => {
        itemBarCodeLength = 0;
        pickPositionCode = '';
        pickPoint = [];
        htmlStr = '';
        // 裁剪商品条码
        vals.pickBillDetail[key].itemBarCodeFirst = ''; // 第一行
        vals.pickBillDetail[key].itemBarCodeSecond = ''; // 第二行前面部分
        vals.pickBillDetail[key].itemBarCodeLast = ''; // 第二行后面部分，需要加粗
        itemBarCodeLength = item.itemBarCode.length;
        if (itemBarCodeLength < 8) {
            vals.pickBillDetail[key].itemBarCodeLast = item.itemBarCode;
        }
        else if (itemBarCodeLength < 16) {
            vals.pickBillDetail[key].itemBarCodeLast = item.itemBarCode.substr(itemBarCodeLength - 7, 7);
            vals.pickBillDetail[key].itemBarCodeSecond = item.itemBarCode.substr(0, itemBarCodeLength - 7);
        }
        else {
            vals.pickBillDetail[key].itemBarCodeFirst = item.itemBarCode.substr(0, itemBarCodeLength - 15);
            vals.pickBillDetail[key].itemBarCodeSecond = item.itemBarCode.substr(itemBarCodeLength - 15, 15);
            vals.pickBillDetail[key].itemBarCodeLast = item.itemBarCode
                .substr(itemBarCodeLength - 15, 15)
                .substr(8, 7);
            vals.pickBillDetail[key].itemBarCodeSecond = item.itemBarCode.substr(itemBarCodeLength - 15, 8);
        }
        if (vals.pickBillDetail[key].itemBarCodeFirst !== '') {
            vals.pickBillDetail[key].itemBarCodeFirst += '\n';
        }
        vals.pickBillDetail[key].itemBarCodeStr =
            vals.pickBillDetail[key].itemBarCodeFirst + vals.pickBillDetail[key].itemBarCodeFirst;
        // 裁剪货位
        pickPositionCode = item.pickPositionCode.split('-');
        vals.pickBillDetail[key].pickPositionCodeFirst = '';
        vals.pickBillDetail[key].pickPositionCodeSecond = '';
        if (pickPositionCode.length > 3) {
            vals.pickBillDetail[key].pickPositionCodeFirst = `${pickPositionCode
                .splice(0, pickPositionCode.length - 3)
                .join('-')}-`;
            vals.pickBillDetail[key].pickPositionCodeSecond = pickPositionCode
                .splice(pickPositionCode.length - 3, 3)
                .join('-');
        }
        else {
            vals.pickBillDetail[key].pickPositionCodeSecond = pickPositionCode?.join('-');
        }
        // 指引调整
        vals.pickBillDetail[key].pickPoint.forEach((point, index) => {
            if ((index + 1) % 4 === 0) {
                if (point.packagePickNo.length > 3) {
                    if (point.pickNum < 10) {
                        pickPoint.push(`[<span style="font-weight: bold;">
                ${doReplaceLetter(point.packagePickNo)}
                </span>]<span style="font-size: 11pt">x</span><span style="font-weight: bold"><span style="font-weight: bold">
                ${point.pickNum}
                </span>
                </span>`);
                    }
                    else if (point.pickNum >= 10 && point.pickNum < 100) {
                        pickPoint.push(`[<span style="font-weight: bold;">
                ${doReplaceLetter(point.packagePickNo)}
                </span>]<span style="font-size: 11pt">x</span><span style="font-weight: bold">
                ${point.pickNum}
                </span>`);
                    }
                    else {
                        pickPoint.push(`[<span style="font-weight: bold;">
                ${doReplaceLetter(point.packagePickNo)}
                </span>]<span style="font-size: 11pt">x</span><span style="font-weight: bold">
                ${point.pickNum}
                </span>`);
                    }
                }
                else if (point.pickNum < 10) {
                    pickPoint.push(`[<span style="font-weight: bold;">
              ${doReplaceLetter(point.packagePickNo)}
              </span>]<span style="font-size: 11pt">x</span><span style="font-weight: bold">
              ${point.pickNum}
              </span>`);
                }
                else if (point.pickNum >= 10 && point.pickNum < 100) {
                    pickPoint.push(`[<span style="font-weight: bold;">
              ${doReplaceLetter(point.packagePickNo)}
              </span>]<span style="font-size: 11pt">x</span><span style="font-weight: bold">
              ${point.pickNum}
              </span>`);
                }
                else {
                    pickPoint.push(`[<span style="font-weight: bold;">
              ${doReplaceLetter(point.packagePickNo)}
              </span>]<span style="font-size: 11pt">x</span><span style="font-weight: bold">
              ${point.pickNum}
              </span>`);
                }
            }
            else if (point.packagePickNo.length > 3) {
                if (point.pickNum < 10) {
                    pickPoint.push(`[<span style="font-weight: bold;">
              ${doReplaceLetter(point.packagePickNo)}
              </span>]<span style="font-size: 11pt">x</span><span style="font-weight: bold">
              ${point.pickNum}
              '</span>
              <span style="color: #fff;font-size: 4pt ">11</span>`);
                }
                else if (point.pickNum >= 10 && point.pickNum < 100) {
                    pickPoint.push(`[<span style="font-weight: bold;">' +
              ${doReplaceLetter(point.packagePickNo)}
              </span>]<span style="font-size: 11pt">x</span><span style="font-weight: bold">
              ${point.pickNum}
              </span>
              <span style="color: #fff;font-size: 4pt ">1</span>`);
                }
                else {
                    pickPoint.push(`[<span style="font-weight: bold;">
              ${doReplaceLetter(point.packagePickNo)}
              </span>]<span style="font-size: 11pt">x</span><span style="font-weight: bold">
              ${point.pickNum}
              </span>`);
                }
            }
            else if (point.pickNum < 10) {
                pickPoint.push(`[<span style="font-weight: bold;">
            ${doReplaceLetter(point.packagePickNo)}
            </span>]<span style="font-size: 11pt">x</span><span style="font-weight: bold">
            ${point.pickNum}
            </span>
            <span style="color: #fff;font-size: 4pt">111</span>`);
            }
            else if (point.pickNum >= 10 && point.pickNum < 100) {
                pickPoint.push(`[<span style="font-weight: bold;">
            ${doReplaceLetter(point.packagePickNo)}
            '</span>]<span style="font-size: 11pt">x</span><span style="font-weight: bold">' +
            ${point.pickNum}
            </span>
            <span style="color: #fff;font-size: 4pt">11</span>`);
            }
            else {
                pickPoint.push(`[<span style="font-weight: bold;">
            ${doReplaceLetter(point.packagePickNo)}
            </span>]<span style="font-size: 11pt">x</span><span style="font-weight: bold">
            ${point.pickNum}
            </span>
            <span style="color: #fff;font-size: 4pt">1</span>`);
            }
        });
        while (pickPoint.length > 0) {
            htmlStr += `
        <div style="height: 5mm; overflow: hidden; line-height: 5mm; margin-bottom: 1mm;">
          ${pickPoint.splice(0, 4)}
        </div>
      `;
        }
        if (htmlStr === '') {
            vals.pickBillDetail[key].pickPoint = '';
        }
        else {
            vals.pickBillDetail[key].pickPoint = `
        <body style="font-size: 14pt; font-family: Microsoft YaHei">
        ${htmlStr.split(',').join('')}
        </body>
      `;
        }
    });
    return vals;
}
function transformTmalldeliveryOrder(input) {
    const vals = input;
    vals.mixBoxText = '';
    if (vals.mixBox === true) {
        vals.mixBoxText = $i18n.get({ id: '31255239126552576.CNTM', dm: '混箱', ns: 'CnPrint' });
    }
    return vals;
}
function transformYD(input) {
    const vals = input;
    vals.orderPrice = '';
    if (vals.showOrderPrice) {
        vals.orderPrice = $i18n.get({
            id: 'OrderAmountValsOrderAmount',
            dm: '订单金额：  {valsOrderAmount}',
            ns: 'CnPrint',
        }, { valsOrderAmount: vals.orderAmount });
    }
    vals.skuTitle = $i18n.get({ id: '31255238929420288.CNTM', dm: '单价', ns: 'CnPrint' });
    if (vals.showSKUPrice === false) {
        vals.skuTitle = '';
        vals.packageItemDetail = vals.packageItemDetail.map((item) => ({
            ...item,
            itemTagPrice: '',
        }));
    }
    return vals;
}
function transfromDangDang(input) {
    const vals = input;
    vals.subTitle = $i18n.get({
        id: 'DangdangExpressValsConsignCity',
        dm: '当当快递({valsConsignCity}',
        ns: 'CnPrint',
    }, { valsConsignCity: vals.consignCity });
    vals.needPay = '';
    if (vals.cod === 1) {
        vals.subTitle = `COD-${vals.subTitle}`;
        vals.subTitle = `${vals.subTitle}COD`;
        vals.needPay = $i18n.get({
            id: 'PayablevalsArAmount',
            dm: '应付款：{valsArAmount}',
            ns: 'CnPrint',
        }, { valsArAmount: vals.arAmount });
    }
    vals.subTitle += ')';
    return vals;
}
function transformLRLZ(input) {
    const vals = input;
    vals.packageItemDetail.forEach((v, k) => {
        vals.packageItemDetail[k].serial = v.serial + 1;
    });
    return vals;
}
function transfromSF(input) {
    const vals = input;
    vals.wayBillVos = vals.wayBillVos.map((val) => {
        const item = val;
        // 101当日达 103 次日达 108 多日达
        if (item.unionFlag) {
            if (item.unionFlag === '101') {
                item.unionFlag = 'https://img.alicdn.com/tps/TB19jIFKFXXXXXvapXXXXXXXXXX-590-100.png';
            }
            else if (item.unionFlag === '103') {
                item.unionFlag = 'https://img.alicdn.com/tps/TB1tkA_KFXXXXX5XXXXXXXXXXXX-590-100.png';
            }
            else if (item.unionFlag === '108') {
                item.unionFlag = 'https://img.alicdn.com/tps/TB1PPLwNpXXXXcRaXXXXXXXXXXX-221-51.png';
            }
        }
        return item;
    });
    return vals;
}
function transformComplexSort(input) {
    const vals = input;
    vals.printRepickVOList.forEach((detail, k) => {
        vals.printRepickVOList[k].station_waybill_lacks = detail.station_waybill_lacks.toString();
    });
    return vals;
}
function doReplaceLetter(str) {
    let result = str;
    if (result) {
        const tmpstr = result * 1;
        if (Number.isNaN(result)) {
            const str1 = str.substr(1, result.length - 1);
            if (parseInt(str1, 10) < 10) {
                result = `${result.substr(0, 1)}0${str1}`;
            }
        }
        else if (tmpstr < 10) {
            result = `0${tmpstr}`;
        }
        return result;
    }
    else {
        return result;
    }
}
export default localPrint;
