// import $i18n from '@alife/panda-i18n';
import { share } from 'rxjs';
import WebSocketClient from './client';
import { DW, noop, PRINT_CONFIG } from '../const';
class Print extends WebSocketClient {
    static bussinessType = 1;
    messages$;
    input$;
    isReady = false;
    constructor(opt) {
        const defaultUrl = PRINT_CONFIG[DW].socketUrl;
        const { url = defaultUrl, wetConnectRetry = 0 } = opt;
        super({
            url,
            name: PRINT_CONFIG[DW].name,
        });
        this.WET_MAX_ATTEMPTS = wetConnectRetry;
        this.messages$ = this.messages?.pipe(share());
        this.input$ = this.input;
    }
    doPrint(data) {
        this.input$?.next(typeof data === 'string' ? data : JSON.stringify(data));
    }
    subscribe(callback = noop) {
        const subscription = this.messages$?.subscribe({
            next: (data) => callback({
                success: true,
                data,
            }),
            error: (error) => callback({
                success: false,
                data: {},
                error: {
                    message: error.message,
                },
            }),
            complete: () => callback({
                success: true,
                data: {},
                complete: true,
            }),
        });
        return () => {
            subscription?.unsubscribe();
        };
    }
}
export default Print;
