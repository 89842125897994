import $i18n from '@alife/panda-i18n';
import { Observable } from 'rxjs';
import { getUUID } from 'src/utils';
const LOCAL_PRINT_MESSAGE_TYPE = 3;
function cloudPrint(printData = {}, config = {}) {
    return new Observable((observer) => {
        const printDataByConfig = {
            ...printData.printConfig,
            data: [printData],
        };
        let printTimes = config.printTimes || 1;
        const bus_id = (printData.printConfig && printData.printConfig.bus_id) || -1;
        const { printConfig = {} } = printData;
        const { extraData = {}, printer } = printConfig;
        if (printTimes >= 1) {
            const documents = filterDoc(printDataByConfig);
            if (!documents) {
                throw new Error($i18n.get({
                    id: 'PrintDocumentProtocolIncorrect',
                    dm: '打印文档协议不正确',
                    ns: 'CnPrint',
                }));
            }
            const { printType = '' } = extraData;
            printTimes -= 1;
            return observer.next({
                messageType: LOCAL_PRINT_MESSAGE_TYPE,
                bus_id,
                data: {
                    requestID: getUUID(),
                    version: '1.0',
                    cmd: 'print',
                    task: {
                        printer: printer || '',
                        preview: false,
                        previewType: 'pdf',
                        printType,
                        documents,
                        taskID: `${new Date().getTime()}${getUUID()}`,
                    },
                    ...extraData,
                },
                configName: getConfigName(printDataByConfig.data[0], config),
            });
        }
        if (config.refresh) {
            try {
                const refresh = config?.refresh?.split?.('@');
                if (refresh[0] && refresh[1]) {
                    const refreshBtn = document.querySelector(refresh[1]);
                    refreshBtn?.click?.();
                    // 埋点，看有没有使用的地方
                    window?.coneArmsTrace?.logCustom({
                        p1: 'cn-domain-print-refresh-click',
                        p2: refreshBtn,
                    });
                }
            }
            catch (e) {
                console.error(e);
            }
        }
    });
}
function getConfigName(res, config) {
    if (config && config.configName) {
        return config.configName;
    }
    if (res.printConfig && res.printConfig.configName) {
        return res.printConfig.configName;
    }
    return '';
}
export function filterDoc(doc) {
    const documents = [];
    if (!doc.data || !doc.data.length) {
        throw new Error('print data error!');
    }
    for (let i = 0; i < doc.data.length; i++) {
        const nextDoc = getDocument(doc.data[i]);
        if (!nextDoc) {
            return false;
        }
        documents.push(nextDoc);
    }
    return documents;
}
export function getDocs(printData = {}, config = {}) {
    const { printConfig = {} } = printData;
    const { extraData = {} } = printConfig;
    const printDataByConfig = {
        ...printConfig,
        data: [printData],
    };
    const bus_id = (printConfig && printConfig.bus_id) || -1;
    let printTimes = config.printTimes || 1;
    if (printTimes >= 1) {
        const documents = filterDoc(printDataByConfig);
        if (!documents) {
            throw new Error($i18n.get({
                id: 'PrintDocumentProtocolIncorrect',
                dm: '打印文档协议不正确',
                ns: 'CnPrint',
            }));
        }
        const { printType = '' } = extraData;
        printTimes -= 1;
        return {
            messageType: LOCAL_PRINT_MESSAGE_TYPE,
            bus_id,
            data: {
                requestID: getUUID(),
                version: '1.0',
                cmd: 'print',
                task: {
                    printer: '',
                    preview: false,
                    previewType: 'pdf',
                    printType,
                    documents,
                    taskID: `${new Date().getTime()}${getUUID()}`,
                },
                ...extraData,
            },
            configName: getConfigName(printDataByConfig.data[0], config),
        };
    }
    if (config.refresh) {
        try {
            const refresh = config?.refresh?.split?.('@');
            if (refresh[0] && refresh[1]) {
                const refreshBtn = document.querySelector(refresh[1]);
                refreshBtn?.click?.();
                // 埋点，看有没有使用的地方
                window?.coneArmsTrace?.logCustom({
                    p1: 'cn-domain-print-refresh-click',
                    p2: refreshBtn,
                });
            }
        }
        catch (e) {
            // todo
        }
    }
}
function getDocument(docData) {
    const documentID = getDocumentID();
    const contents = getContents(docData);
    return {
        documentID,
        contents,
    };
}
function getDocumentID() {
    return getUUID();
}
function getContents(data) {
    const contents = [];
    const templateUrl = getTemplateUrlFromConfig(data);
    const signature = getSignatureFromConfig(data);
    let list = data.data || [];
    for (let i = 0; i < list.length; i++) {
        let content = {};
        const printData = getPrintData(list[i]);
        const printTemplateUrl = getTemplateUrl(list[i]) || templateUrl;
        const printSignature = getSignature(list[i]) || signature;
        content = {
            data: printData,
            templateURL: printTemplateUrl,
            signature: printSignature,
        };
        contents.push(content);
    }
    list = (data.encryptedData || []);
    for (let i = 0; i < list.length; i++) {
        contents.push(list[i]);
    }
    return contents;
}
function getPrintData(data) {
    let res = data;
    res = HTMLDecode(data);
    return res;
}
function getTemplateUrl(data) {
    if (data.templateUrl) {
        return data.templateUrl;
    }
    return false;
}
function getSignature(data) {
    if (data.signature) {
        return data.signature;
    }
    return false;
}
function getTemplateUrlFromConfig(data) {
    if (data?.printConfig?.templateUrl) {
        return data.printConfig.templateUrl;
    }
    return '';
}
function getSignatureFromConfig(data) {
    if (data.printConfig && data.printConfig.signature) {
        return data.printConfig.signature;
    }
    return '';
}
function HTMLDecode(text) {
    // ae仓模板渲染失败临时解决方案
    if (text?.extraInfo?.dhlImageWaybillUrl) {
        return text;
    }
    const temp = document.createElement('div');
    temp.innerHTML = JSON.stringify(text);
    const output = (temp.innerText || temp.textContent);
    try {
        return JSON.parse(output);
    }
    catch (error) {
        console.log('HTMLDecode数据解析失败', error);
        return text;
    }
}
export default cloudPrint;
