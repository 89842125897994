import $i18n from '@alife/panda-i18n';
import { share } from 'rxjs';
import WebSocketClient from './client';
import * as socketStatus from '../const';
import { noop } from '../const';
class Print extends WebSocketClient {
    static bussinessType = 1;
    messages$;
    input$;
    isReady = false;
    constructor(opt) {
        const defaultUrl = window.location.protocol !== 'https' ? 'ws://localhost:5000' : 'wss://localhost:18653';
        const { url = defaultUrl, wetConnectRetry = 0 } = opt;
        super({
            url,
            name: $i18n.get({ id: 'SpellDuoduoPrint', dm: '拼多多打印', ns: 'CnPrint' }),
        });
        this.WET_MAX_ATTEMPTS = wetConnectRetry;
        this.messages$ = this.messages?.pipe(share());
        this.input$ = this.input;
        this.connectionStatus?.subscribe((status) => {
            if (status === socketStatus.WEBSOCKET_OPENED) {
                this.input$?.next(JSON.stringify({
                    cmd: 'setPrinterConfig',
                    requestID: '123458976',
                    version: '1.0',
                    printer: {
                        name: $i18n.get({ id: 'PDDFaceSinglePrinter', dm: 'PDD面单打印机', ns: 'CnPrint' }),
                        PrintTopLogo: true,
                        PrintBottomLogo: false,
                        horizontalOffset: 2,
                        verticalOffset: 2,
                    },
                }));
            }
        });
    }
    doPrint(data) {
        this.input$?.next(typeof data === 'string' ? data : JSON.stringify(data));
    }
    subscribe(callback = noop) {
        const subscription = this.messages$?.subscribe({
            next: (data) => callback({
                success: true,
                data,
            }),
            error: (error) => callback({
                success: false,
                data: {},
                error: {
                    message: error.message,
                },
            }),
            complete: () => callback({
                success: true,
                data: {},
                complete: true,
            }),
        });
        return () => {
            subscription?.unsubscribe();
        };
    }
}
export default Print;
