import { getUUID } from 'src/utils';
import EventManager from './eventManager';
import { DW, AKC } from '../const';
const GETPRINTERS = 'getPrinters'; // 获取打印机列表
const GETPRINTERCONFIGBYPRINTERNAME = 'getPrinterConfig'; // 通过打印机名称获取打印机配置
const SETPRINTERCONFIG = 'setPrinterConfig'; // 设置打印机配置
const GETPRINRTASKSTATUS = 'print'; // 获取下发打印任务收到确认返回结果
const NOTIFYPRINTRESULT = 'notifyPrintResult'; // 获取打印结果返回
const GETTASKSTATUSBYTASKID = 'getTaskStatus'; // 指定taskID获取打印结果
const GETGLOBALCONFIG = 'getGlobalConfig'; // 获取全局配置
const SETGLOBALCONFIG = 'setGlobalConfig'; // 设置全局配置
const GETAGENTINFO = 'getAgentInfo'; // 获取客户端版本信息
const GETPRINTCONFIGNAMELIST = 'getPrintConfigNameList'; // 获取打印配置列表
const BUSINESSTYPE = 1; // 业务类型
const MESSAGETYPE = 6; // 新版消息类型
const LOGMESSAGETYPE = 9; // 日志消息类
const CONFIGMESSAGETYPE = 5; // 打印配置消息类型
const OLDPRINTMESSAGETYPE = 3; // 旧版打印消息类型
const OLDGETCONFIGMESSAGETYPE = 5; // 旧版获取打印配置
const CAINIAO = 'CAINIAO'; // 菜鸟源
const PDD = 'PDD'; // 拼多多
const JD = 'JD'; // 京东
const BYTE = 'BYTE'; // 字节 todo 参考
const KUAISHOU = 'KUAISHOU'; // 快手
const VIP = 'VIP'; // 唯品会
const HUAWEI = 'HUAWEI'; // 华为商城
const XHS = 'XHS'; // 小红书
const WXSPH = 'WXSPH'; // 微信视频号
const PRINTRESULTNOTIFY = 'PrintResultNotify'; // 拼多多打印结果
const SUCCESS = 'success';
const FAILED = 'failed';
const NOTIFYPRINTERROR = 'notifyPrintError'; // 打印失败
const commConfig = {
    bussinessType: BUSINESSTYPE,
    messageType: MESSAGETYPE,
};
const DOLOGHOST = {
    daily: 'http://ibird-print.cainiao.test:7001',
    pre: 'https://ibird-platform-pre.cainiao.com',
    online: 'https://ibird-print.cainiao.com',
};
const COMMENDCONFIG = {
    getPrinters: () => {
        return {
            ...commConfig,
            data: {
                requestID: getUUID(),
                version: '1.0',
                cmd: GETPRINTERS,
            },
        };
    },
    getPrinterConfig: (printer = '') => {
        return {
            ...commConfig,
            data: {
                requestID: getUUID(),
                version: '1.0',
                cmd: GETPRINTERCONFIGBYPRINTERNAME,
                printer,
            },
        };
    },
    setPrinterConfig: (config = {}) => {
        return {
            ...commConfig,
            data: {
                requestID: getUUID(),
                version: '1.0',
                cmd: SETPRINTERCONFIG,
            },
            printer: config,
        };
    },
    printData: (config) => {
        const { printConfig = {}, data = [] } = config;
        const { dataSource = CAINIAO, traceId = '', pdfPrint } = printConfig;
        const taskID = `${new Date().getTime()}${getUUID()}`; // 前端默认taskID
        // 串行打印
        if (dataSource === AKC) {
            const [{ taskId: akcTaskId }] = data;
            printConfig._taskID = akcTaskId;
        }
        else {
            printConfig._taskID = taskID;
        }
        try {
            EventManager.Instance.addTaskCache(taskID, traceId);
        }
        catch (e) {
            console.error(e);
        }
        if (dataSource === CAINIAO) {
            if (EventManager.Instance.supposeCloud) {
                return {
                    requestID: getUUID(),
                    version: '1.0',
                    cmd: GETPRINRTASKSTATUS,
                    task: {
                        taskID,
                        preview: printConfig.preview,
                        printer: printConfig.printer,
                        previewType: printConfig.previewType || 'pdf',
                        printType: printConfig.printType,
                        documents: data,
                    },
                    pageSession: {
                        pageName: printConfig.pageName || '',
                        pageCode: printConfig.pageCode || '',
                        pageUrl: window.location.href,
                    },
                    ...printConfig,
                };
            }
            const nextConfig = {
                ...commConfig,
                configName: printConfig.configName,
                data: {
                    requestID: getUUID(),
                    version: '1.0',
                    cmd: GETPRINRTASKSTATUS,
                    task: {
                        taskID,
                        preview: printConfig.preview,
                        printer: printConfig.printer,
                        previewType: printConfig.previewType || 'pdf',
                        documents: data,
                        printType: '',
                    },
                    pageSession: {
                        pageName: printConfig.pageName || '',
                        pageCode: printConfig.pageCode || '',
                        pageUrl: window.location.href,
                    },
                    ...printConfig,
                },
            };
            if (pdfPrint) {
                nextConfig.data.task.printType = 'dirctPrint';
            }
            return nextConfig;
        }
        if (dataSource === PDD) {
            return {
                ERPId: printConfig.ERPId,
                cmd: 'print',
                requestID: getUUID(),
                task: {
                    documents: data,
                    notifyType: ['print'],
                    preview: printConfig.preview,
                    previewType: printConfig.previewType || 'pdf',
                    printer: printConfig.printer,
                    taskID,
                },
                version: '1.0',
            };
        }
        if (dataSource === JD) {
            const doc = {
                orderType: printConfig.preview ? 'PRE_View' : 'PRINT',
                key: taskID,
            };
            // 京东新协议
            if (printConfig.version === '2') {
                doc.version = 2;
                doc.parameters = {
                    printName: printConfig.printer,
                    contents: data,
                };
            }
            // 京东老协议
            else {
                doc.parameters = {
                    printName: printConfig.printer,
                    ...data,
                };
            }
            return doc;
        }
        if (dataSource === BYTE) {
            return {
                cmd: 'print',
                requestID: getUUID(),
                version: '1.0',
                task: {
                    taskID,
                    preview: printConfig.preview,
                    printer: printConfig.printer,
                    documents: data,
                },
            };
        }
        if (dataSource === KUAISHOU) {
            return {
                cmd: 'print',
                requestID: getUUID(),
                task: {
                    documents: data,
                    preview: printConfig.preview,
                    previewType: printConfig.previewType || 'pdf',
                    printer: printConfig.printer,
                    taskID,
                },
                version: '1.0',
            };
        }
        if (dataSource === VIP) {
            return {
                cmd: 'print',
                task: '打印任务',
                actionType: 'PRINT',
                traceId: taskID,
                storeId: printConfig.storeId,
                channel: printConfig.channel,
                platformTemplateURL: printConfig?.platformTemplateURL || '',
                customData: printConfig?.vopCustomData || {},
                customerTemplate: printConfig?.customerTemplate || '',
                printParamDTO: Array.isArray(data) ? data[0] : data,
                printerName: printConfig.printer || printConfig.configName || '',
            };
        }
        if (dataSource === HUAWEI) {
            const [item = {}] = data; // data即documents，华为只会有一项@阿亨，{ Cmd, requestId, version, printDataList }
            const { cmd } = item;
            return {
                ...item,
                Cmd: cmd,
                taskID,
            };
        }
        if (dataSource === XHS) {
            return {
                cmd: 'print',
                requestID: getUUID(),
                version: '1.0',
                task: {
                    taskID,
                    preview: printConfig.preview,
                    printer: printConfig.printer,
                    previewType: printConfig.previewType || 'pdf',
                    documents: data,
                },
            };
        }
        if (dataSource === WXSPH) {
            const taskList = data.map((item, index) => ({
                taskID: index === 0 ? taskID : `${new Date().getTime()}${getUUID()}`,
                ...item,
            }));
            return {
                command: 'print',
                version: '2.0',
                requestID: getUUID(),
                taskList,
                printType: 1,
                printer: printConfig.printer,
                size: printConfig.size, // 纸张尺寸，单位毫米，printType 为 2 时必传
            };
        }
        if (dataSource === DW) {
            // https://open.dewu.com/#/doc?id=1014080095&pid=1010515087&type=article
            const messageData = {
                cmd: 'print',
                requestID: taskID,
                version: '1.0',
                task: {
                    isvName: printConfig.resName || 'xinyi',
                    taskID,
                    printer: printConfig.printer,
                    documents: data,
                },
            };
            /*
            // task 起始 document 序号（非必填，用于打印面单页码）
            if ('firstDocumentNumber' in printConfig) {
              messageData.task.firstDocumentNumber = printConfig.firstDocumentNumber;
            }
             // task document 总数（非必填，用于打印面单页码）
            if ('totalDocumentCount' in printConfig) {
              messageData.task.totalDocumentCount = printConfig.totalDocumentCount;
            }
            */
            return messageData;
        }
        if (dataSource === AKC) {
            const documents = data.map((item) => ({
                ...item,
                printName: printConfig.printer,
            }));
            return {
                esubrc: 'SendPdfToPrintes',
                documents,
                params: printConfig.params,
                printName: printConfig.printer,
            };
        }
    },
    getTaskStatus: (taskID = []) => {
        return {
            ...commConfig,
            data: {
                requestID: getUUID(),
                version: '1.0',
                cmd: GETTASKSTATUSBYTASKID,
                taskID,
            },
        };
    },
    getGlobalConfig: () => {
        return {
            ...commConfig,
            data: {
                requestID: getUUID(),
                version: '1.0',
                cmd: GETGLOBALCONFIG,
            },
        };
    },
    setGlobalConfig: (notifyOnTaskFailure = false) => {
        return {
            ...commConfig,
            data: {
                requestID: getUUID(),
                version: '1.0',
                cmd: SETGLOBALCONFIG,
                notifyOnTaskFailure,
            },
        };
    },
    getAgentInfo: () => {
        return {
            ...commConfig,
            data: {
                requestID: getUUID(),
                version: '1.0',
                cmd: GETAGENTINFO,
            },
        };
    },
    getConfigName: () => ({
        bussinessType: BUSINESSTYPE,
        messageType: CONFIGMESSAGETYPE,
    }),
    getPrintConfigNameList: () => ({
        bussinessType: BUSINESSTYPE,
        messageType: MESSAGETYPE,
        data: {
            requestID: getUUID(),
            version: '1.0',
            cmd: GETPRINTCONFIGNAMELIST,
        },
    }),
    printLocalLog: (message) => ({
        bussinessType: BUSINESSTYPE,
        messageType: LOGMESSAGETYPE,
        message,
    }),
};
export { GETPRINTERS, GETPRINTERCONFIGBYPRINTERNAME, SETPRINTERCONFIG, GETPRINRTASKSTATUS, NOTIFYPRINTRESULT, GETTASKSTATUSBYTASKID, GETGLOBALCONFIG, SETGLOBALCONFIG, GETAGENTINFO, BUSINESSTYPE, MESSAGETYPE, OLDPRINTMESSAGETYPE, OLDGETCONFIGMESSAGETYPE, COMMENDCONFIG, DOLOGHOST, CONFIGMESSAGETYPE, PRINTRESULTNOTIFY, SUCCESS, FAILED, NOTIFYPRINTERROR, };
