import $i18n from '@alife/panda-i18n';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { tap, retryWhen, delay } from 'rxjs/operators';
import WetSocket from './socket';
const WET_STATUS_NOT_CONNECTED = 0;
const WET_STATUS_WAIT_CONNECT_ACK = 1;
const WET_STATUS_CONNECTED = 2;
/**
 * @description Wet基础类
 * @author 武曲(xiachen.gxc@cainiao.com)
 * @date 2018-10-18
 * @class Wet
 */
class Wet {
    /**
     * @description wet重连最大次数
     * @static
     * @memberof Wet
     */
    WET_MAX_ATTEMPTS = 10;
    /**
     * @description wet状态
     * @memberof Wet
     */
    wetStatus = new BehaviorSubject(0);
    /**
     * @description wet当前重试次数
     * @memberof Wet
     */
    wetAttemptTimes = new BehaviorSubject(0);
    /**
     * @description wet模块名称，用于日志
     * @memberof Wet
     */
    name;
    /**
     * @description wet初始化消息messageType
     * @memberof Wet
     */
    initMessageType;
    /**
     * @description wet模块 业务编码
     * @memberof Wet
     */
    bussinessType;
    /**
     * @description wet模块 半自动打包messageType
     * @memberof Wet
     */
    semiAutomaticPackagingMessageType = 7;
    /**
     * @description wet模块 半自动打包bussinessType
     * @memberof Wet
     */
    semiAutomaticPackagingBussinessType = 1;
    /**
     * 是否半自动打包的标
     */
    semiAutomaticPackagingStartFlag = 0;
    /**
     * @description wet服务地址
     * @memberof Wet
     */
    url;
    /**
     * @description 给wet发送请求的对象
     * @memberof Wet
     */
    input;
    /**
     * @description wet当前版本
     * @memberof Wet
     */
    version = '0.0.0';
    /**
     * @description wet当前umid
     * @memberof Wet
     */
    umid = '';
    connectionStatus;
    messages;
    isReady = false;
    /**
     *Creates an instance of Wet.
     * @author 武曲(xiachen.gxc@cainiao.com)
     * @date 2018-10-18
     * @param {*} [opt={}]
     * @memberof Wet
     */
    constructor(opt = {}) {
        const { url = 'wss://localhost:8080', bussinessType, name = 'unknow wet module', initMessageType = 1, } = opt;
        this.name = name;
        this.initMessageType = initMessageType;
        if (bussinessType) {
            this.bussinessType = bussinessType;
        }
        this.url = url;
        this._init();
    }
    /**
     * @description 初始化函数
     * @author 武曲(xiachen.gxc@cainiao.com)
     * @date 2018-10-18
     * @memberof Wet
     * @private
     */
    _init() {
        if (this.bussinessType && this.bussinessType < 0) {
            const bizType = this.bussinessType || '';
            const errorMsg = $i18n.get({
                id: 'InitializationExceptionBusinessMustBeGreater_706397336',
                dm: '初始化异常：业务必须大于0，当前业务类型:{bizType}',
                ns: 'CnPrint',
            }, { bizType });
            this.logger(errorMsg);
            this.wetStatus.next(WET_STATUS_NOT_CONNECTED);
            try {
                this.collLogger('error', $i18n.get({ id: 'WETRegistrationException', dm: 'WET 注册异常', ns: 'CnPrint' }));
            }
            catch (e) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                const message = (e && e.message);
                if (message) {
                    this.logger(message);
                }
            }
            throw new Error(errorMsg);
        }
        this.input = new Subject();
        // this.input = new Subject(0); TS 报错构造函数没有入参，暂时去除
        const { messages } = WetSocket({
            url: this.url,
            input: this.input,
        });
        this.messages = messages
            .pipe(tap((message) => {
            if (this.wetStatus.getValue() === 0) {
                return this.register$(message);
            }
            if (this.wetStatus.getValue() === 1) {
                return this.checkRegister$(message);
            }
        }))
            .pipe(retryWhen((err) => err.pipe(tap(() => {
            this.wetStatus.next(WET_STATUS_NOT_CONNECTED);
            if (this.wetAttemptTimes.getValue() >= this.WET_MAX_ATTEMPTS) {
                this.wetAttemptTimes.next(WET_STATUS_NOT_CONNECTED);
                this.logger($i18n.get({
                    id: 'FailedToConnectToTheWET_364429775',
                    dm: '连接WET服务失败',
                    ns: 'CnPrint',
                }));
                try {
                    this.collLogger('error', $i18n.get({ id: 'WETInterrupt', dm: 'WET 中断', ns: 'CnPrint' }));
                }
                catch (e) {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    const { message } = e || {};
                    if (message) {
                        this.logger(message);
                    }
                }
                throw new Error($i18n.get({
                    id: 'FailedToConnectToTheWET_364429775',
                    dm: '连接WET服务失败',
                    ns: 'CnPrint',
                }));
            }
            else {
                this.wetAttemptTimes.next(this.wetAttemptTimes.getValue() + 1);
                const newTime = this.wetAttemptTimes?.getValue?.() || 0;
                this.logger($i18n.get({
                    id: 'AWebsocketConnectionErrorOccurredThe_119975534',
                    dm: 'websocket连接发生错误，尝试重新连接中, 当前第 {newTime} 次尝试',
                    ns: 'CnPrint',
                }, { newTime }));
            }
        }), delay(5000))));
    }
    /**
     * @description 日志
     * @author 武曲(xiachen.gxc@cainiao.com)
     * @date 2018-10-18
     * @param {*} message
     * @param {string} [type='normal']
     * @memberof Wet
     */
    logger(message, type = 'normal') {
        const messageWithName = `[${this.name}]: ${message}`;
        switch (type) {
            case 'normal': {
                console.log(messageWithName); // eslint-disable-line
                break;
            }
            case 'warn': {
                console.warn(messageWithName); // eslint-disable-line
                break;
            }
            case 'error': {
                console.error(messageWithName); // eslint-disable-line
                break;
            }
            default: {
                console.log(messageWithName); // eslint-disable-line
            }
        }
    }
    /**
     * @description 给wet发送信息前的序列化函数
     * @author 武曲(xiachen.gxc@cainiao.com)
     * @date 2018-10-18
     * @param {*} data
     * @returns {void}
     * @memberof Wet
     */
    wetStringify(data) {
        return String.fromCharCode(2) + JSON.stringify(data) + String.fromCharCode(3);
    }
    stringify() {
        return (source) => new Observable((observer) => source.subscribe({
            next(x) {
                observer.next(String.fromCharCode(2) + JSON.stringify(x) + String.fromCharCode(3));
            },
            error(err) {
                observer.error(err);
            },
            complete() {
                observer.complete();
            },
        }));
    }
    isWetReady() {
        return this.wetStatus.getValue() === WET_STATUS_CONNECTED;
    }
    /**
     * @description
     * @author 武曲(xiachen.gxc@cainiao.com)
     * @date 2019-05-20
     * @param {string} [type='log']
     * @param {string} [message='']
     * @memberof Wet
     */
    collLogger(type = 'log', message = '') {
        console.log('collLogger', type, message);
    }
    /**
     * @description 处理第一次发送消息时wet注册
     * @author 武曲(xiachen.gxc@cainiao.com)
     * @date 2018-10-18
     * @param {*} message
     * @memberof Wet
     */
    register$(message) {
        this.wetStatus.next(WET_STATUS_WAIT_CONNECT_ACK);
        const parsedMessage = JSON.parse(message);
        if (!parsedMessage.success) {
            this.logger($i18n.get({
                id: 'InitializationExceptionmessage',
                dm: '初始化异常：{message}',
                ns: 'CnPrint',
            }, { message }), 'error');
            this.wetStatus.next(WET_STATUS_NOT_CONNECTED);
            try {
                this.collLogger('error', $i18n.get({ id: 'WETInitializationException', dm: 'WET 初始化异常', ns: 'CnPrint' }));
            }
            catch (e) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const { message: tmpMsg } = e || {};
                if (tmpMsg) {
                    this.logger(tmpMsg);
                }
            }
            throw new Error($i18n.get({ id: 'InitializationException', dm: '初始化异常', ns: 'CnPrint' }));
        }
        if (parsedMessage.version) {
            this.version = parsedMessage.version;
        }
        if (parsedMessage.umid) {
            this.umid = parsedMessage.umid;
        }
        this.logger($i18n.get({ id: 'InInitialization', dm: '初始化中', ns: 'CnPrint' }));
        this.input?.next(this.wetStringify({
            bussinessType: this.bussinessType,
            messageType: this.initMessageType,
        }));
    }
    /**
     * @description 确认wet是否注册成功
     * @author 武曲(xiachen.gxc@cainiao.com)
     * @date 2018-10-18
     * @param {*} message
     * @memberof Wet
     */
    checkRegister$(message) {
        const parsedMessage = JSON.parse(message);
        if (!parsedMessage.success) {
            this.logger($i18n.get({
                id: 'RegistrationExceptionmessage',
                dm: '注册异常：{message}',
                ns: 'CnPrint',
            }, { message }), 'error');
            this.wetStatus.next(WET_STATUS_NOT_CONNECTED);
            try {
                this.collLogger('error', $i18n.get({ id: 'WETRegistrationException', dm: 'WET 注册异常', ns: 'CnPrint' }));
            }
            catch (e) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const { message: tmpMsg } = e || {};
                if (tmpMsg) {
                    this.logger(tmpMsg);
                }
            }
            throw new Error($i18n.get({ id: 'RegistrationException', dm: '注册异常', ns: 'CnPrint' }));
        }
        if (parsedMessage.umid) {
            this.umid = parsedMessage.umid;
        }
        this.logger($i18n.get({ id: 'RegistrationSuccessful', dm: '注册成功', ns: 'CnPrint' }));
        this.wetAttemptTimes.next(WET_STATUS_NOT_CONNECTED);
        this.wetStatus.next(WET_STATUS_CONNECTED);
    }
}
/** Wet模块导出 */
export default Wet;
