import * as React from 'react';
import { CnButton } from '@alife/cn-ui';
import './index.scss';
export function TestButton({ className = '', content = '', onClick = () => {
    // eslint-disable-next-line no-console
    console.log('default clicked');
}, }) {
    return (React.createElement("div", { className: `my-component-wrap ${className}` },
        React.createElement(CnButton, { className: "my-blue-btn", onClick: onClick }, "test btn"),
        "test btn",
        React.createElement("div", null, content)));
}
TestButton.displayName = 'TestButton';
